import css from 'styled-jsx/css'

export const TabsStyle = css.global`
  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 0;
    font-size: 14px;
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0;
    font-size: 16px;
  }
  .ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 6px 16px;
  }
  .ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 7px 16px 6px;
  }
  .ant-tabs-rtl {
    direction: rtl;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 0 32px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
    margin-left: 0;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
    margin-left: 12px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
    margin-right: 8px;
    margin-left: -4px;
  }
  .ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
    order: 0;
  }
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
    order: 0;
  }
  .ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
    order: 1;
  }
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 0;
    margin-left: 2px;
  }
  .ant-tabs-dropdown-rtl {
    direction: rtl;
  }
  .ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
    text-align: right;
  }
  .ant-tabs-top,
  .ant-tabs-bottom {
    flex-direction: column;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 16px 0;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 1px solid #f0f0f0;
    content: '';
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 2px;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: width 0.3s, left 0.3s, right 0.3s;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    top: 0;
    bottom: 0;
    width: 30px;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    left: 0;
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: 0;
    box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
    opacity: 1;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
    opacity: 1;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    bottom: 0;
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    order: 1;
    margin-top: 16px;
    margin-bottom: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    top: 0;
  }
  .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    top: 0;
  }
  .ant-tabs-bottom > .ant-tabs-content-holder,
  .ant-tabs-bottom > div > .ant-tabs-content-holder {
    order: 0;
  }
  .ant-tabs-left > .ant-tabs-nav,
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-left > div > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    flex-direction: column;
    min-width: 50px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 16px 0;
    padding: 8px 24px;
    text-align: center;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:last-of-type,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:last-of-type {
    margin-bottom: 0;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    right: 0;
    left: 0;
    height: 30px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
    top: 0;
    box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    bottom: 0;
    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
    opacity: 1;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
    opacity: 1;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    width: 2px;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
    transition: height 0.3s, top 0.3s;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
    flex-direction: column;
  }
  .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
    right: 0;
  }
  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid #f0f0f0;
  }
  .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 24px;
  }
  .ant-tabs-right > .ant-tabs-nav,
  .ant-tabs-right > div > .ant-tabs-nav {
    order: 1;
  }
  .ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
  }
  .ant-tabs-right > .ant-tabs-content-holder,
  .ant-tabs-right > div > .ant-tabs-content-holder {
    order: 0;
    margin-right: -1px;
    border-right: 1px solid #f0f0f0;
  }
  .ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
  .ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-right: 24px;
  }
  .ant-tabs-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ant-tabs-dropdown-hidden {
    display: none;
  }
  .ant-tabs-dropdown-menu {
    max-height: 200px;
    margin: 0;
    padding: 4px 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-tabs-dropdown-menu-item {
    min-width: 120px;
    margin: 0;
    padding: 5px 12px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-tabs-dropdown-menu-item:hover {
    background: #f5f5f5;
  }
  .ant-tabs-dropdown-menu-item-disabled,
  .ant-tabs-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background: transparent;
    cursor: not-allowed;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    padding: 8px 16px;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--primary);
    background: #fff;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
    visibility: hidden;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-right: 2px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 2px 0 0;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #fff;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 0 2px 2px;
  }
  .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-top-color: #fff;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
    margin-bottom: 2px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 2px 0 0 2px;
  }
  .ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-right-color: #fff;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0 2px 2px 0;
  }
  .ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
    border-left-color: #fff;
  }
  .ant-tabs {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    display: flex;
    overflow: hidden;
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    position: relative;
    display: inline-block;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    content: '';
    pointer-events: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: transform 0.3s;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: flex;
    align-self: stretch;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: 8px 16px;
    background: transparent;
    border: 0;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    transform: translateY(100%);
    content: '';
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    min-width: 40px;
    padding: 0 8px;
    background: #fafafa;
    border: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
    outline: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
    color: var(--primary);
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
    color: #096dd9;
  }
  .ant-tabs-extra-content {
    flex: none;
  }
  .ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
  .ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
    justify-content: center;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: var(--primary);
    pointer-events: none;
  }
  .ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 32px 0 0;
    padding: 12px 0;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: #8c8c8c;
  }
  .ant-tabs-tab:last-of-type {
    margin-right: 0;
    margin-left: 0;
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: #096dd9;
  }
  .ant-tabs-tab-btn {
    outline: none;
  }
  .ant-tabs-tab-remove {
    flex: none;
    margin-right: -4px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-tabs-tab-remove:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tabs-tab:hover {
    color: var(--primary);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262626;
    font-weight: 500;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
  .ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
    color: rgba(0, 0, 0, 0.25);
  }
  .ant-tabs-tab .ant-tabs-tab-remove .anticon {
    margin: 0;
  }
  .ant-tabs-tab .anticon {
    margin-right: 12px;
  }
  .ant-tabs-content {
    display: flex;
    width: 100%;
  }
  .ant-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }
  .ant-tabs-content-animated {
    transition: margin 0.3s;
  }
  .ant-tabs-tabpane {
    flex: none;
    width: 100%;
    outline: none;
  }
`
