import React, { FC, useEffect, useState } from 'react'
import { Modal } from '@freec/corelib/components/share/ANTD/Modal'
import { useNotificationModalState } from '../../hooks/useNotification'
import { getFirebaseMessageFCMToken, isFirebaseSuppored } from '../../firebase'
import { useTranslation } from '@freec/corelib/hooks/UserTranslation'
import Image from 'next/image'
import NEW_EMAIL_IMAGE from './assests/new-email.webp'
const NotificationModal: FC = () => {
  const { translate } = useTranslation()
  const [openNotificationModal, setOpenNotificationModal] = useNotificationModalState()
  const [isFireBaseSupport, setIsFirebaseSupport] = useState(false)

  const toggleOpenNotificationModal = () => {
    setOpenNotificationModal(!openNotificationModal)
  }

  function handleTurnOnNotification() {
    toggleOpenNotificationModal()
    getFirebaseMessageFCMToken()
  }
  useEffect(() => {
    isFirebaseSuppored()
      .then((supported) => {
        setIsFirebaseSupport(supported)
      })
      .catch((e) => {
        if (e !== 'firebase_message_not_support') {
          throw e
        }
      })
  }, [])

  return (
    <>
      <style jsx global>
        {`
          .ant-modal-wrap {
            position: fixed;
            width: 100%;
            height: ;
            height: 100%;
            top: 0;
            background-color: rgba(89, 89, 89, 0.8);
          }

          .notification {
            text-align: center;
          }

          .notification .ant-modal-body {
            border-radius: 5px;
          }

          .notification svg {
            width: 48px;
            height: 48px;
            fill: #857eff;
          }

          .notification-bell {
            margin-bottom: 16px;
          }

          .notification-title {
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            color: #262626;
            margin-bottom: 8px;
          }

          .notification-summary {
            font-size: 16px;
            line-height: 1.5;
            color: #595959;
            margin-bottom: 24px;
          }

          .notification-btn {
            display: inline-block;
            font-size: 14px;
            line-height: 24px;
            color: #595959;
            font-weight: 500;
            width: calc(50% - 8px);
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 8px;
          }

          .notification-btn.-blue {
            background-color: var(--primary);
            color: #fff;
          }

          .justify-content-between {
            justify-content: space-between !important;
          }
        `}
      </style>
      <div className="notification-modal">
        <Modal
          className="notification"
          width={384}
          footer={null}
          visible={openNotificationModal && isFireBaseSupport && Notification.permission === 'default'}
          closable={false}>
          <div>
            <div className="notification-bell">
              <Image alt="email" src={NEW_EMAIL_IMAGE} />
            </div>
            <div className="notification-title">{translate('notification:get_notification')}</div>
            <div className="notification-summary">{translate('notification:you_can_turn_off')}</div>
            <div className="d-flex justify-content-between">
              <a className="notification-btn" onClick={toggleOpenNotificationModal}>
                {translate('notification:later')}
              </a>
              <a className="notification-btn -blue" onClick={handleTurnOnNotification}>
                {translate('notification:accept')}
              </a>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default NotificationModal
