import Head from 'next/head'
import Footer from '../components/Footer'
import Header from '../components/Header'
import env from '../env'
import Script from 'next/script'
import CrispChat from '../utillities/CrispChat'
import { ResetStyle } from './reset.style'
import GleapWidget from '../utillities/GleapWidget'
import { getClient } from '../utillities/getIsServer'
import { useCallback, useEffect } from 'react'
import useAppRouter from '../hooks/useAppRouter'

const withLayout = (Page?: any, className?: string, resetStyle = false) => {
  const HOCCom = (props: any) => {
    const router = useAppRouter()
    const NotificationComponents = props.NotificationComponents
    const checkAndOpenChat = useCallback(() => {
      if (router.query.openChat) {
        window.Gleap.open()
      }
    }, [router])
    useEffect(() => {
      if (window.Gleap) {
        checkAndOpenChat()
      }
    }, [checkAndOpenChat, router])
    return (
      <>
        {resetStyle && (
          <style jsx global>
            {ResetStyle}
          </style>
        )}
        <Head>
          <link rel="apple-touch-icon" href={`${env.PUBLIC_APP_DOMAIN}/img/apple-touch-icon.png`} />
          <link rel="shortcut icon" type="image/png" sizes="32x32" href="/favicon_32x32.png"></link>
          <link rel="shortcut icon" type="image/png" sizes="16x16" href="/favicon_16x16.png"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com"></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
            rel="stylesheet"
            media="print"
            // eslint-disable-next-line react/no-unknown-property
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            // onLoad="this.media='all'"
          />
        </Head>
        <div className={className || 'ant-layout company-layout'}>
          <Header NotificationComponents={NotificationComponents} />
          <div className="container">
            <Page {...props} />
          </div>
          <Footer />
        </div>
        {env.PUBLIC_GTM_KEY && (
          <Script
            id="gtm-script"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${env.PUBLIC_GTM_KEY}');
            `
            }}
          />
        )}
        {env.PUBLIC_GTM_KEY && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${env.PUBLIC_GTM_KEY}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}></iframe>
          </noscript>
        )}
        {env.PUBLIC_ENABLE_LIVE_CHAT === 'true' && <CrispChat />}
        {env.NEXT_PUBLIC_ENABLE_GLEAP && getClient() && <GleapWidget apiKey={env.NEXT_PUBLIC_ENABLE_GLEAP} />}
      </>
    )
  }

  HOCCom.getInitialProps = Page.getInitialProps
  HOCCom.getStaticProps = Page.getStaticProps
  return HOCCom
}

export default withLayout
