import css from 'styled-jsx/css'

export const InputStyle = css.global`
  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s;
    display: inline-flex;
  }
  .ant-input-affix-wrapper::-moz-placeholder {
    opacity: 1;
  }
  .ant-input-affix-wrapper::placeholder {
    color: #bfbfbf;
  }
  .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input-affix-wrapper:hover {
    border-color: var(--primary);
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--primary);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-rtl .ant-input-affix-wrapper:focus,
  .ant-input-rtl .ant-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-affix-wrapper-borderless,
  .ant-input-affix-wrapper-borderless:hover,
  .ant-input-affix-wrapper-borderless:focus,
  .ant-input-affix-wrapper-borderless-focused,
  .ant-input-affix-wrapper-borderless-disabled,
  .ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-affix-wrapper-sm {
    padding: 0px 7px;
  }
  .ant-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none;
  }
  .ant-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ant-input-prefix,
  .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
  }
  .ant-input-prefix {
    margin-right: 4px;
  }
  .ant-input-suffix {
    margin-left: 4px;
  }
  .ant-input-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;
    margin: 0 4px;
    vertical-align: -1px;
  }
  .ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-clear-icon:active {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input-clear-icon:last-child {
    margin-right: 0;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .ant-input-textarea-clear-icon {
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 8px 8px 0 0;
  }
  .ant-input-textarea-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-input-textarea-clear-icon:active {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input-textarea-clear-icon + i {
    margin-left: 6px;
  }
  .ant-input-textarea-clear-icon-hidden {
    visibility: hidden;
  }
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: #141921;
    font-size: 16px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    transition: all 0.3s;
  }
  .ant-input::-moz-placeholder {
    opacity: 1;
  }
  .ant-input::placeholder {
    color: #7a7c82;
  }
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ant-input:hover {
    border-color: var(--primary);
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: var(--primary);
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-rtl .ant-input:focus,
  .ant-input-rtl .ant-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important;
  }
  .ant-input-borderless,
  .ant-input-borderless:hover,
  .ant-input-borderless:focus,
  .ant-input-borderless-focused,
  .ant-input-borderless-disabled,
  .ant-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ant-input-lg {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-sm {
    padding: 0px 7px;
  }
  .ant-input-rtl {
    direction: rtl;
  }
  .ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ant-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ant-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .ant-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap,
  .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ant-input-group-addon:not(:first-child):not(:last-child),
  .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ant-input-group-addon,
  .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .ant-input-group-wrap > * {
    display: block !important;
  }
  .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
  }
  .ant-input-group-addon .ant-select {
    margin: -5px -11px;
  }
  .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
  }
  .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: var(--primary);
  }
  .ant-input-group-addon > i:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
  .ant-input-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ant-input-group > .ant-input:last-child,
  .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
  .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-lg .ant-input,
  .ant-input-group-lg > .ant-input-group-addon {
    padding: 6.5px 11px;
    font-size: 16px;
  }
  .ant-input-group-sm .ant-input,
  .ant-input-group-sm > .ant-input-group-addon {
    padding: 0px 7px;
  }
  .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 40px;
  }
  .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 24px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-input-group.ant-input-group-compact {
    display: block;
  }
  .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: '';
  }
  .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: '';
  }
  .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: inline-flex;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
  .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-focused {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
    z-index: 1;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ant-input-group > .ant-input-rtl:first-child,
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9;
  }
  .ant-input-group-rtl.ant-input-group > .ant-input:last-child,
  .ant-input-group-rtl.ant-input-group-addon:last-child {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
    border-radius: 0 2px 2px 0;
  }
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
  .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px;
  }
  .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-input-password-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input[type='color'] {
    height: 32px;
  }
  .ant-input[type='color'].ant-input-lg {
    height: 40px;
  }
  .ant-input[type='color'].ant-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .ant-input-search-icon {
    padding: 0 9px;
  }
  .ant-input-search-icon::before {
    transform: translateX(-10px);
  }
  .ant-input-search-icon::after {
    width: 32px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon {
    padding: 0 12px;
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::before {
    transform: translateX(-13px);
  }
  .ant-input-affix-wrapper-lg .ant-input-search-icon::after {
    width: 40px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon {
    padding: 0 6px;
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::before {
    transform: translateX(-7px);
  }
  .ant-input-affix-wrapper-sm .ant-input-search-icon::after {
    width: 24px;
  }
  .ant-input-search-icon {
    margin-left: 0.5em;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-input-search-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-input-search-icon::before {
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    border-left: 1px solid #d9d9d9;
    transition: all 0.3s;
    content: '';
  }
  .ant-input-search-icon::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    content: '';
  }
  .ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 0;
  }
  .ant-input-search-enter-button input {
    border-right: 0;
  }
  .ant-input-search-enter-button input:hover,
  .ant-input-search-enter-button input:focus {
    border-color: var(--primary);
  }
  .ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon,
  .ant-input-search-enter-button input + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-input-group-wrapper-rtl {
    direction: rtl;
  }
  .ant-input-group-rtl {
    direction: rtl;
  }
  .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
    outline: none;
  }
  .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 4px;
  }
  .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 4px 0 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-input-affix-wrapper-rtl .ant-input-textarea-clear-icon {
    right: auto;
    left: 0;
    margin: 8px 0 0 8px;
  }
  .ant-input-search-rtl {
    direction: rtl;
  }
  .ant-input-search-rtl .ant-input-search-icon {
    margin-right: 0.5em;
    margin-left: 0;
  }
  .ant-input-search-rtl .ant-input-search-icon::before {
    border-left: none;
  }
  .ant-input-search-rtl .ant-input-search-icon::after {
    right: auto;
    left: 0;
    border-right: 1px solid #d9d9d9;
    transition: all 0.3s;
  }
  .ant-input-search-rtl.ant-input-search:not(.ant-input-search-enter-button) {
    padding-right: 11px;
    padding-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button input:hover,
  .ant-input-search-rtl.ant-input-search-enter-button input:focus {
    border-color: var(--primary);
  }
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper {
    border-right: 1px solid #d9d9d9;
    border-left: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:hover,
  .ant-input-search-rtl.ant-input-search-enter-button.ant-input-affix-wrapper:focus {
    border-color: var(--primary);
  }
  .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon,
  .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon {
    padding: 0;
    border: 0;
  }
  .ant-input-search-rtl.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
  .ant-input-search-rtl.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
    width: 100%;
    border-radius: 2px 0 0 2px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ant-input {
      height: 32px;
    }
    .ant-input-lg {
      height: 40px;
    }
    .ant-input-sm {
      height: 24px;
    }
    .ant-input-affix-wrapper > input.ant-input {
      height: auto;
    }
  }
`
