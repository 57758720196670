import css from 'styled-jsx/css'

export const FormStyle = css.global`
  .ant-form-item .ant-mentions,
  .ant-form-item textarea.ant-input {
    height: auto;
  }
  .ant-form-item .ant-upload {
    background: transparent;
  }
  .ant-form-item .ant-upload.ant-upload-drag {
    background: #fafafa;
  }
  .ant-form-item input[type='radio'],
  .ant-form-item input[type='checkbox'] {
    width: 14px;
    height: 14px;
  }
  .ant-form-item .ant-radio-inline,
  .ant-form-item .ant-checkbox-inline {
    display: inline-block;
    margin-left: 8px;
    font-weight: normal;
    vertical-align: middle;
    cursor: pointer;
  }
  .ant-form-item .ant-radio-inline:first-child,
  .ant-form-item .ant-checkbox-inline:first-child {
    margin-left: 0;
  }
  .ant-form-item .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical {
    display: block;
  }
  .ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
  .ant-form-item .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0;
  }
  .ant-form-item .ant-input-number + .ant-form-text {
    margin-left: 8px;
  }
  .ant-form-item .ant-input-number-handler-wrap {
    z-index: 2;
  }
  .ant-form-item .ant-select,
  .ant-form-item .ant-cascader-picker {
    width: 100%;
  }
  .ant-form-item .ant-input-group .ant-select,
  .ant-form-item .ant-input-group .ant-cascader-picker {
    width: auto;
  }
  .ant-form-inline {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-form-inline .ant-form-item {
    flex: none;
    flex-wrap: nowrap;
    margin-right: 16px;
    margin-bottom: 0;
  }
  .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label,
  .ant-form-inline .ant-form-item > .ant-form-item-control {
    display: inline-block;
    vertical-align: top;
  }
  .ant-form-inline .ant-form-item > .ant-form-item-label {
    flex: none;
  }
  .ant-form-inline .ant-form-item .ant-form-text {
    display: inline-block;
  }
  .ant-form-inline .ant-form-item .ant-form-item-has-feedback {
    display: inline-block;
  }
  .ant-form-horizontal .ant-form-item-label {
    flex-grow: 0;
  }
  .ant-form-horizontal .ant-form-item-control {
    flex: 1 1 0;
  }
  .ant-form-vertical .ant-form-item {
    flex-direction: column;
  }
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form-vertical .ant-form-item-label > label,
  .ant-col-24.ant-form-item-label > label,
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-vertical .ant-form-item-label > label::after,
  .ant-col-24.ant-form-item-label > label::after,
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-vertical .ant-form-item-label,
  .ant-form-rtl.ant-col-24.ant-form-item-label,
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
  @media (max-width: 575px) {
    .ant-form-item .ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-form-item .ant-form-item-label > label {
      margin: 0;
    }
    .ant-form-item .ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-form-item .ant-form-item-label {
      text-align: right;
    }
    .ant-form .ant-form-item {
      flex-wrap: wrap;
    }
    .ant-form .ant-form-item .ant-form-item-label,
    .ant-form .ant-form-item .ant-form-item-control {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ant-col-xs-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-xs-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xs-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 767px) {
    .ant-col-sm-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-sm-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-sm-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 991px) {
    .ant-col-md-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-md-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-md-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-md-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1199px) {
    .ant-col-lg-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-lg-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-lg-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
      text-align: right;
    }
  }
  @media (max-width: 1599px) {
    .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 8px;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
    }
    .ant-col-xl-24.ant-form-item-label > label {
      margin: 0;
    }
    .ant-col-xl-24.ant-form-item-label > label::after {
      display: none;
    }
    .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
      text-align: right;
    }
  }
  .ant-form-item {
  }
  .ant-form-item-has-feedback .ant-input {
    padding-right: 24px;
  }
  .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 18px;
  }
  .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: 28px;
  }
  .ant-form-item-has-feedback .ant-switch {
    margin: 2px 0 4px;
  }
  .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback > .ant-select .ant-select-clear,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
  .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
    padding-right: 42px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 19px;
  }
  .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: 32px;
  }
  .ant-form-item-has-feedback .ant-picker {
    padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-large {
    padding-right: 29.2px;
  }
  .ant-form-item-has-feedback .ant-picker-small {
    padding-right: 25.2px;
  }
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    width: 32px;
    height: 20px;
    margin-top: -10px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    visibility: visible;
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    pointer-events: none;
  }
  .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon svg,
  .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #52c41a;
    animation-name: diffZoomIn1 !important;
  }
  .ant-form-item-has-warning .ant-form-item-explain,
  .ant-form-item-has-warning .ant-form-item-split {
    color: #faad14;
  }
  .ant-form-item-has-warning .ant-input,
  .ant-form-item-has-warning .ant-input-affix-wrapper,
  .ant-form-item-has-warning .ant-input:hover,
  .ant-form-item-has-warning .ant-input-affix-wrapper:hover {
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-input:focus,
  .ant-form-item-has-warning .ant-input-affix-wrapper:focus,
  .ant-form-item-has-warning .ant-input-focused,
  .ant-form-item-has-warning .ant-input-affix-wrapper-focused {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input:not(.ant-form-item-has-warning .ant-input-disabled) {
    background-color: #fff;
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper:not(.ant-form-item-has-warning .ant-input-affix-wrapper-disabled) {
    background-color: #fff;
  }
  .ant-form-item-has-warning .ant-input-affix-wrapper input:focus {
    box-shadow: none !important;
  }
  .ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-prefix {
    color: #faad14;
  }
  .ant-form-item-has-warning .ant-input-group-addon {
    color: #faad14;
    border-color: #faad14;
  }
  .ant-form-item-has-warning .has-feedback {
    color: #faad14;
  }
  .ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #faad14;
    animation-name: diffZoomIn3 !important;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border-color: #faad14 !important;
  }
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
  .ant-form-item-has-warning .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-number,
  .ant-form-item-has-warning .ant-picker {
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-input-number-focused,
  .ant-form-item-has-warning .ant-picker-focused,
  .ant-form-item-has-warning .ant-input-number:focus,
  .ant-form-item-has-warning .ant-picker:focus {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  }
  .ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-warning .ant-picker:not([disabled]):hover {
    border-color: #faad14;
  }
  .ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  }
  .ant-form-item-has-error .ant-form-item-explain,
  .ant-form-item-has-error .ant-form-item-split {
    color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input,
  .ant-form-item-has-error .ant-input-affix-wrapper,
  .ant-form-item-has-error .ant-input:hover,
  .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input:focus,
  .ant-form-item-has-error .ant-input-affix-wrapper:focus,
  .ant-form-item-has-error .ant-input-focused,
  .ant-form-item-has-error .ant-input-affix-wrapper-focused {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled) {
    background-color: #fff;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-affix-wrapper-disabled) {
    background-color: #fff;
  }
  .ant-form-item-has-error .ant-input-affix-wrapper input:focus {
    box-shadow: none !important;
  }
  .ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-input-prefix {
    color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-group-addon {
    color: #ff4d4f;
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .has-feedback {
    color: #ff4d4f;
  }
  .ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #ff4d4f;
    animation-name: diffZoomIn2 !important;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border-color: #ff4d4f !important;
  }
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-open .ant-select-selector,
  .ant-form-item-has-error .ant-select:not(.ant-select-borderless).ant-select-focused .ant-select-selector {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  .ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number,
  .ant-form-item-has-error .ant-picker {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-input-number-focused,
  .ant-form-item-has-error .ant-picker-focused,
  .ant-form-item-has-error .ant-input-number:focus,
  .ant-form-item-has-error .ant-picker:focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-input-number:not([disabled]):hover,
  .ant-form-item-has-error .ant-picker:not([disabled]):hover {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
  .ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ff7875;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  }
  .ant-form-item-has-error .ant-transfer-list {
    border-color: #ff4d4f;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
  .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  .ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper {
    border-color: #ff4d4f !important;
  }
  .ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ff4d4f;
  }
  .ant-form-item-has-error-leave .ant-form-item-explain {
    color: #ff4d4f;
  }
  .ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
    display: inline-block;
    color: var(--primary);
  }
  .ant-form {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
  }
  .ant-form legend {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: inherit;
    border: 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-form label {
    font-size: 14px;
  }
  .ant-form input[type='search'] {
    box-sizing: border-box;
  }
  .ant-form input[type='radio'],
  .ant-form input[type='checkbox'] {
    line-height: normal;
  }
  .ant-form input[type='file'] {
    display: block;
  }
  .ant-form input[type='range'] {
    display: block;
    width: 100%;
  }
  .ant-form select[multiple],
  .ant-form select[size] {
    height: auto;
  }
  .ant-form input[type='file']:focus,
  .ant-form input[type='radio']:focus,
  .ant-form input[type='checkbox']:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  .ant-form output {
    display: block;
    padding-top: 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
  }
  .ant-form .ant-form-text {
    display: inline-block;
    padding-right: 8px;
  }
  .ant-form-small .ant-form-item-label > label {
    height: 24px;
  }
  .ant-form-small .ant-form-item-control-input {
    min-height: 24px;
  }
  .ant-form-large .ant-form-item-label > label {
    /* height: 40px; */
  }
  .ant-form-large .ant-form-item-control-input {
    min-height: 40px;
  }
  .ant-form-item {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    margin-bottom: 24px;
    vertical-align: top;
  }
  .ant-form-item-with-help {
    margin-bottom: 0;
  }
  .ant-form-item-hidden,
  .ant-form-item-hidden.ant-row {
    display: none;
  }
  .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }
  .ant-form-item-label-left {
    text-align: left;
  }
  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
  .ant-form-item-label > label > .anticon {
    font-size: 14px;
    vertical-align: top;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-label > label .ant-form-item-optional {
    display: inline-block;
    margin-left: 4px;
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
    display: none;
  }
  .ant-form-item-label > label::after {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
  .ant-form-item-label > label.ant-form-item-no-colon::after {
    content: ' ';
  }
  .ant-form-item-control {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 100%;
  }
  .ant-form-item-control-input {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
  }
  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    clear: both;
    min-height: 24px;
    padding-top: 0px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .show-help-enter,
  .show-help-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .show-help-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-play-state: paused;
  }
  .show-help-enter.show-help-enter-active,
  .show-help-appear.show-help-appear-active {
    animation-name: antShowHelpIn;
    animation-play-state: running;
  }
  .show-help-leave.show-help-leave-active {
    animation-name: antShowHelpOut;
    animation-play-state: running;
    pointer-events: none;
  }
  .show-help-enter,
  .show-help-appear {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .show-help-leave {
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  @keyframes antShowHelpIn {
    0% {
      transform: translateY(-5px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes antShowHelpOut {
    to {
      transform: translateY(-5px);
      opacity: 0;
    }
  }
  @keyframes diffZoomIn1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn2 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes diffZoomIn3 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  .ant-form-rtl {
    direction: rtl;
  }
  .ant-form-rtl .ant-form-item-label {
    text-align: left;
  }
  .ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
    margin-right: 0;
    margin-left: 4px;
  }
  .ant-form-rtl .ant-form-item-label > label::after {
    margin: 0 2px 0 8px;
  }
  .ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
    margin-right: 4px;
    margin-left: 0;
  }
  .ant-col-rtl .ant-form-item-control:first-child {
    width: 100%;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input {
    padding-right: 11px;
    padding-left: 24px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 11px;
    padding-left: 18px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
    padding: 0;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
    right: auto;
    left: 28px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
    padding-left: 18px;
  }
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
    right: auto;
    left: 32px;
  }
  .ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
  .ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
    padding-right: 0;
    padding-left: 42px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
    margin-right: 0;
    margin-left: 19px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
    right: auto;
    left: 32px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker {
    padding-right: 11px;
    padding-left: 29.2px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
    padding-right: 11px;
    padding-left: 29.2px;
  }
  .ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
    padding-right: 7px;
    padding-left: 25.2px;
  }
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
  .ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    right: auto;
    left: 0;
  }
  .ant-form-rtl.ant-form-inline .ant-form-item {
    margin-right: 0;
    margin-left: 16px;
  }
`
