import css from 'styled-jsx/css'

export const ResetStyle = css.global`
  :root {
    --layout-header-height: 64px;
    --layout-header-height-mobile: 50px;
    --layout-footer-height: 58px;
    --layout-content-min-height: calc(100vh - var(--layout-footer-height) - var(--layout-header-height));
    --black: #000;
    --white: #fff;
    --primary: #6d4fed;
    --blue: #1890ff;
    --grey-5: #d9d9d9;
    --grey-6: #bfbfbf;
    --grey-7: #8c8c8c;
    --grey-8: #595959;
    --grey-9: #262626;
    --red: #ea4335;
    --neutral: #f6f8fc;
    --highlight: #fa8c16;
  }

  .ant-layout,
  .ant-layout * {
    box-sizing: border-box;
  }

  .ant-layout {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: auto;
    -ms-flex: auto;
    flex: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0;
    background: #f0f2f5;
  }

  [class^='ant-']::-ms-clear,
  [class*='ant-']::-ms-clear,
  [class^='ant-'] input::-ms-clear,
  [class*='ant-'] input::-ms-clear,
  [class^='ant-'] input::-ms-reveal,
  [class*='ant-'] input::-ms-reveal {
    display: none;
  }
  [class^='ant-'],
  [class*='ant-'],
  [class^='ant-'] *,
  [class*='ant-'] *,
  [class^='ant-'] *::before,
  [class*='ant-'] *::before,
  [class^='ant-'] *::after,
  [class*='ant-'] *::after {
    box-sizing: border-box;
  }

  body [tabindex='-1']:focus {
    outline: none;
  }
  html,
  body {
    width: 100%;
    height: 100%;
  }
  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-ms-viewport {
    width: device-width;
  }
  body {
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-variant: tabular-nums;
    line-height: 1.5715;
    background-color: #fff;
    font-feature-settings: 'tnum';
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: var(--black);
    background-color: var(--neutral);
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    -webkit-appearance: none;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1em;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 500;
  }
  dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1em;
  }
  dfn {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  a:hover {
    color: #40a9ff;
  }
  a:active {
    color: #096dd9;
  }
  a:active,
  a:hover {
    text-decoration: none;
    outline: 0;
  }
  a:focus {
    text-decoration: none;
    outline: 0;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  a,
  area,
  button,
  [role='button'],
  input:not([type='range']),
  label,
  select,
  summary,
  textarea {
    touch-action: manipulation;
  }
  table {
    border-collapse: collapse;
  }
  th {
    text-align: inherit;
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }
  body [hidden] {
    display: none;
  }
  ::selection {
    color: #fff;
    background: var(--primary);
  }
  .clearfix::before {
    display: table;
    content: '';
  }
  .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }

  a {
    text-decoration: none;
  }

  .relative {
    position: relative;
  }

  .wrapper {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }

  .wrapper-container {
    max-width: 1170px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }
  .wrapper-container-job {
    max-width: 996px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 768px) {
    .wrapper-container {
      max-width: 720px;
    }
  }
  button > span {
    pointer-events: none;
  }
  .horizontal-line {
    border-bottom: 1px solid #e8e8e8;
  }
  .f-size-18 {
    font-size: 18px;
  }
  .f-size-20 {
    font-size: 20px;
  }
  .text-blue {
    color: var(--primary);
  }
  body .new-color-text {
    color: #141921;
  }
  .text-16-blue {
    font-size: 16px;
    color: var(--primary);
  }
  .color-gray-7 {
    color: #8c8c8c;
    font-size: 16px;
  }

  body .mb-0 {
    margin-bottom: 0;
  }
  body .mb-2 {
    margin-bottom: 12px;
  }
  body .mb-4 {
    margin-bottom: 24px;
  }
  body .mt-2 {
    margin-top: 12px;
  }
  body .mt-3 {
    margin-top: 18px;
  }
  body .mt-4 {
    margin-top: 24px;
  }
  body .pl-4 {
    padding-left: 24px;
  }
  body .mr-3px {
    margin-right: 3px;
  }
  body .mr-2 {
    margin-right: 12px;
  }
  body .m-2 {
    margin: 12px;
  }
  body .mb-4 {
    margin-bottom: 24px;
  }
  body .mb-3 {
    margin-bottom: 16px;
  }
  body .ml-1 {
    margin-left: 6px;
  }
  body .ml-2 {
    margin-left: 12px;
  }
  body .mr-1 {
    margin-right: 6px;
  }
  body .ml-8px {
    margin-left: 8px;
  }
  body .p-3 {
    padding: 6px;
  }
  body .p-0-imp {
    padding: 0;
  }
  body .border-none {
    border: 0 none;
  }
  .w-100 {
    width: 100%;
  }

  .anticon-spin::before {
    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  .anticon-spin {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: inline-block;
    animation: loadingCircle 1s infinite linear;
  }
  @keyframes loadingCircle {
    100% {
      transform: rotate(360deg);
    }
  }
`
