import { useAmp } from 'next/amp'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { FC, useState } from 'react'
import useAppRouter from '../../hooks/useAppRouter'

import {
  LINK_URL_JOBS,
  LINK_URL_ROOT,
  LINK_URL_CANDIDATES_CV,
  LINK_URL_SEARCH,
  LINK_URL_CANDIDATE_REGISTER
} from '../../utillities/LinkURL'
import LinkWrapper from '../Link'
import LOGO_FREEC_BLUE from './assets/freecracy.svg'
import useCurrentUser from '../../hooks/useCurrentUser'
import { useTranslation } from '../../hooks/useTranslation'
import MessageOutlined from '../base/icons/MessageOutlined'
const SideBarContent = dynamic(() => import('./SideBarContent'))
const SideBarAccountSetting = dynamic(() => import('./SideBarAccountSetting'))

const SideBar: FC<{ NotificationComponents; toggleNotification }> = ({ NotificationComponents, toggleNotification }) => {
  const router = useAppRouter()
  const isAmp = useAmp()
  const [showSidebar, setShowSidebar] = useState(false)
  const [showSetting, setShowSetting] = useState(false)

  const [currentUser] = useCurrentUser()
  const { translate } = useTranslation()
  const isLogIn = !!currentUser

  const toggleSideBar = () => {
    setShowSidebar(!showSidebar)
  }
  const toggleSetting = () => {
    setShowSetting(!showSetting)
  }

  return (
    <div className={`sidebar none-shadow ${[LINK_URL_SEARCH, LINK_URL_JOBS].includes(router.pathname) && 'none-shadow'}`}>
      <button type="button" aria-label="nav-icon" id="nav-icon" className={`${showSidebar ? 'open' : ''}`} onClick={toggleSideBar}>
        <div className="wrap-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      <div className="logo">
        <LinkWrapper prefetch={false} href={LINK_URL_ROOT}>
          <a className="header-logo-img">
            <div className="logo-freec">
              {isAmp === true ? (
                <div style={{ width: 120, height: 32, margin: 'auto' }}>
                  <amp-img width={120} height={32} src={LOGO_FREEC_BLUE?.src} alt="freec-platform" layout="responsive" />
                </div>
              ) : (
                <Image src={LOGO_FREEC_BLUE} layout="fixed" alt="freec-platform" width={120} height={32} />
              )}
            </div>
          </a>
        </LinkWrapper>
        {!isLogIn ? (
          !isAmp && (
            <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATE_REGISTER} as={LINK_URL_CANDIDATE_REGISTER}>
              <a>
                <button type="button" className="btn-register">
                  {translate('header:register')}
                </button>
              </a>
            </LinkWrapper>
          )
        ) : (
          <div className="d-flex">
            <LinkWrapper prefetch={false} href={LINK_URL_CANDIDATES_CV} as={LINK_URL_CANDIDATES_CV}>
              <a>
                <button type="button" className="btn-link mr-0">
                  <span>{translate('header:profile_cv')}</span>
                </button>
              </a>
            </LinkWrapper>
            <LinkWrapper prefetch={false} href={`/chat`}>
              <a style={{ marginLeft: 24 }}>
                <MessageOutlined style={{ fontSize: 24, fill: '#4f535b' }} />
              </a>
            </LinkWrapper>
            {NotificationComponents?.NotificationBell ? (
              <NotificationComponents.NotificationBell toggleNotification={toggleNotification} />
            ) : null}
          </div>
        )}
      </div>
      {showSidebar && <SideBarContent toggleSideBar={toggleSideBar} toggleSetting={toggleSetting} />}
      {showSetting && <SideBarAccountSetting toggleSideBar={toggleSetting} />}
    </div>
  )
}

export default SideBar
