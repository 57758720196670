import { FC } from 'react'
import Image from 'next/image'
import Col from '../../../components/base/Grid/Col'

import IMG_LEFT from '../assets/img/register_1.png'
import IMG_RIGHT from '../assets/img/register_2.png'
import { useTranslation } from '../../../hooks/useTranslation'
import Button from '../../../components/base/buttons/Button'
import useAppRouter from '../../../hooks/useAppRouter'
import { LP_CANDIDATE_BUTTON_GET_STARTED } from '../../../utillities/ConstantAutomation'
import { LINK_URL_EMPLOYER_REGISTER } from '../../../utillities/LinkURL'
import Row from '../../../components/base/Grid/Row'
import { Trans } from 'react-i18next'

const SuccessLPCandidate: FC = () => {
  const router = useAppRouter()
  const { translate } = useTranslation()
  return (
    <div className="success">
      <div className="section-left">
        <Col xs={11} md={6} lg={8} xl={12}>
          <Image src={IMG_LEFT} alt="img-left" />
        </Col>
      </div>
      <div className="section-right">
        <Col xs={8} md={4} lg={8} xl={12}>
          <Image src={IMG_RIGHT} alt="img-right" />
        </Col>
      </div>
      <div className="relative">
        <Row justify="center">
          <Col xs={20} md={16} lg={18} xl={24}>
            <h2 className="heading">
              <Trans i18nKey="page_home:lp_success_heading">
                <span className="high-light" />
              </Trans>
            </h2>
            <div className="sub-title">{translate('page_home:lp_success_sub_title')}</div>
            <Button
              id={LP_CANDIDATE_BUTTON_GET_STARTED}
              className="btn-start"
              onClick={() => {
                router.push(LINK_URL_EMPLOYER_REGISTER)
              }}>
              {translate('page_home:lp_free_job_post')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default SuccessLPCandidate
