import { FC } from 'react'
import Row from '../../../components/base/Grid/Row'
import Col from '../../../components/base/Grid/Col'
import { Trans } from 'react-i18next'
import { useTranslation } from '../../../hooks/useTranslation'
import Image from 'next/image'

import IMAGE_1 from '../assets/img/career_4.png'
import IMAGE_2 from '../assets/img/career_1.png'
import IMAGE_3 from '../assets/img/career_4.png'

import CAREER_ARROW from '../assets/img/career_arrow.svg'

const HelpCareer: FC = () => {
  const { translate } = useTranslation()
  return (
    <div className="career">
      <div className="wrapper-container">
        <Row justify="center">
          <Col xs={24}>
            <h2 className="title">
              <Trans i18nKey="page_home:lp_3_easy_step">
                <span className="high-light" />
              </Trans>
            </h2>
            <div className="sub-title">{translate('page_home:making_your_job_search_easier')}</div>
            <Row>
              <Col xs={24} md={8} lg={8}>
                <div className="career-card">
                  <div className="career-card__img">
                    <Image src={IMAGE_1} alt="image_1" width={208} height={200} />
                  </div>
                  <h3 className="title">{translate('page_home:search_job')}</h3>
                  <div className="description">{translate('page_home:search_for_jobs_in_your_field')}</div>
                  <div className="career-card__arrow">
                    <Image src={CAREER_ARROW} alt="image_1" width={120} height={14} />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="career-card card-2">
                  <div className="career-card__img">
                    <Image src={IMAGE_2} alt="image_2" width={208} height={200} />
                  </div>
                  <h3 className="title">{translate('page_home:create_new_cv')}</h3>
                  <div className="description">{translate('page_home:only_5_of_cv_make_it_past')}</div>
                  <div className="career-card__arrow">
                    <Image src={CAREER_ARROW} alt="image_1" width={120} height={14} />
                  </div>
                </div>
              </Col>
              <Col xs={24} md={8} lg={8}>
                <div className="career-card">
                  <div className="career-card__img">
                    <Image src={IMAGE_3} alt="image_3" width={208} height={200} />
                  </div>
                  <h3 className="title">{translate('page_home:apply_for_job')}</h3>
                  <div className="description">{translate('page_home:find_the_right_jobs_hiring')}</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default HelpCareer
